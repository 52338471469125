<template>
  <div class="firm-internship">
    <div class="searchRadio">
      <el-row :gutter="30">
        <el-radio-group v-model="searchRadio">
          <el-col :span="6"><el-radio :label="3">全部</el-radio></el-col>
          <el-col :span="6"><el-radio :label="0">待审核</el-radio></el-col>
          <el-col :span="6"><el-radio :label="1">已通过</el-radio></el-col>
          <el-col :span="6"><el-radio :label="2">未通过</el-radio></el-col>
        </el-radio-group>
      </el-row>
    </div>
    <div class="main">
      <el-table :data="selectedChat" v-loading="loading" style="width: 100%;">
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="gender" label="性别" width="60"></el-table-column>
        <el-table-column prop="cell_phone" label="手机号" width="120"></el-table-column>
        <el-table-column prop="school" label="学校" show-overflow-tooltip></el-table-column>
        <el-table-column prop="college" label="班级" show-overflow-tooltip></el-table-column>
        <el-table-column prop="job_title" label="实习岗位" show-overflow-tooltip></el-table-column>
        <el-table-column prop="created" label="报名时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="internship_time" label="实习时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="is_state_name" label="状态"></el-table-column>
        <el-table-column label="操作" width="210">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" :disabled="scope.row.is_state === 2" @click="popup(scope.row)">反馈</el-button>
            <el-button type="primary" size="mini" :disabled="scope.row.is_state === 1" @click="operate(scope.row, 1)">通过</el-button>
            <el-button type="warning" size="mini" :disabled="scope.row.is_state === 2" @click="operate(scope.row, 2)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 反馈弹窗 -->
    <el-dialog title="反馈表" :modal="false" :visible.sync="isDialog">
      <el-form ref="feedbackData" :rules="rules" :model="feedbackData">
        <el-row><el-col :span="24">
          <el-row :gutter="20">
            <el-form-item label="1、创新能力" prop="radio01" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio01" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="2、实践动手能力" prop="radio02" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio02" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="3、人际沟通能力" prop="radio03" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio03" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="4、心理素质及抗压能力" prop="radio04" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio04" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="5、合作与协调能力" prop="radio05" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio05" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="6、专业水平" prop="radio06" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio06" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="7、综合评价" prop="textarea" class="custom-label-form-item">
              <el-col :span="24">
                <el-input style="width: 80%;" type="textarea" v-model="feedbackData.textarea"></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="24" style="text-align: right;">
              <el-form-item>
                <el-button type="primary" @click="submitForm('feedbackData')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col></el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getJobInternship, updateJobInternship, submitFeedback } from '@/request/api'

export default {
  data() {
    return {
      searchRadio: 3,
      loading: false,
      tableData: [],
      isDialog: false,
      shop_user_job_internship_id: null,
      feedbackData: {
        radio01: null,
        radio02: null,
        radio03: null,
        radio04: null,
        radio05: null,
        radio06: null,
        textarea: ''
      },
      rules: {
        radio01: [
          { required: true, message: '请选择反馈项', trigger: 'blur' }
        ],
        radio02: [
          { required: true, message: '请选择反馈项', trigger: 'blur' }
        ],
        radio03: [
          { required: true, message: '请选择反馈项', trigger: 'blur' }
        ],
        radio04: [
          { required: true, message: '请选择反馈项', trigger: 'blur' }
        ],
        radio05: [
          { required: true, message: '请选择反馈项', trigger: 'blur' }
        ],
        radio06: [
          { required: true, message: '请选择反馈项', trigger: 'blur' }
        ],
        textarea: [
          { required: true, message: '完善一下综合评价', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    selectedChat() {
      if (this.searchRadio === 3) return this.tableData
      return this.tableData.filter((ele) => ele.is_state === this.searchRadio)
    }
  },
  methods: {
    popup(row) {
      this.isDialog = !this.isDialog
      this.shop_user_job_internship_id = row.shop_user_job_internship_id
      if (row.is_feedback === 1) {
        this.feedbackData = JSON.parse(row.feedback)
      } else {
        this.feedbackData = {
          radio01: null,
          radio02: null,
          radio03: null,
          radio04: null,
          radio05: null,
          radio06: null,
          textarea: ''
        }
      }
    },
    async init() {
      if (this.loading) return

      this.loading = true
      const result = await getJobInternship()
      this.loading = false
      if (result.data.code === "1000") {
        this.tableData = result.data.data.map((item) => {
            if (item.is_state === 0) {
                item.is_state_name = '待审核'
            } else if (item.is_state === 1) {
                item.is_state_name = '已通过'
            } else if (item.is_state === 2) {
                item.is_state_name = '未通过'
            }

            item.internship_time = item.start_internship + ' 至 ' + item.end_internship

            return item
        })
      } else {
        this.$message.error(result.data.msg);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const res = await submitFeedback({
              feedbackData: JSON.stringify(this.feedbackData),
              shop_user_job_internship_id: this.shop_user_job_internship_id
            })
            if (res.status !== 200 || res.data.code !== "1000") return this.$message.error(res.data.msg);

            this.$message.success('提交成功')
            this.isDialog = !this.isDialog
            this.init()
          } catch(error) {
            this.$message.error('提交失败');
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 通过按钮
    async operate(row, is_state) {
        const result = await updateJobInternship({ shop_user_job_internship_id: row.shop_user_job_internship_id, is_state: is_state })
        if (result.data.code === '1000') {
            if (is_state === 1) {
              this.$message({
                type: "success",
                message: "申请已通过",
              });
            } else {
              this.$message({
                type: "warning",
                message: "申请已拒绝",
              });
            }

            this.init()
        } else {
            this.$message.error(result.data.msg);
        }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.firm-internship {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .custom-label-form-item .el-form-item__label {
    font-size: 19px;
    font-weight: bold;
  }
  .feedback-box:last-child {
    margin-bottom: 0;
  }
  .searchRadio {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .main {
    flex: 1;
    overflow-y: auto;
  }
}
</style>